import './style.scss';

export default () => {
  const init = () => {
    const tables = document.querySelectorAll('table');
    if (!tables.length) {
      return false;
    }

    // eslint-disable-next-line
    console.log('tables', tables);

    const wrap = (el, wrapper) => {
      el.parentNode.insertBefore(wrapper, el);
      wrapper.appendChild(el);
      wrapper.classList.add('table-block');
    };

    tables.forEach((table) => {
      wrap(table, document.createElement('div'));
    });
  };

  window.addEventListener('CookiebotOnLoad', function () {
    init();
  });
};
